/* eslint-disable testing-library/no-await-sync-query */
import { useEffect, useState } from 'react'
import { type NextPage } from 'next'
import { type ExperimentValues } from '@stone-payments/experiment/src/lib/types'
import { Spinner } from '@stone-payments/jade'
import { getAllExperiment } from 'platform/lib/experiment'
import { Stores } from 'swh/shared/http'
import { isSwhExperimentOff } from 'swh/shared/utils'

import { useDeusExMachina } from '~/domains/platform/infra/deus-ex-machina/hocs/with-deus-ex-machina'
import { type Entity, type Subject } from '~/domains/platform/infra/deus-ex-machina/types'
import BlackBird from '~/domains/platform/lib/blackbird'
import { isValidCpf } from '~/lib/helpers'

import { useSwhConfigContext } from './swh-config-context'

const WithSwhConfig = ({ Page, ...props }: any) => {
  const { deusExMachina } = useDeusExMachina()
  // @ts-ignore CREDIT CHANGE TO RUN CHECK-TYPES SUCCESSFULLY
  const [config, setConfig] = useSwhConfigContext()
  const [experiment, setExperiment] = useState<ExperimentValues | undefined>(undefined)
  const entity = deusExMachina?.entity as Entity<'acquirer'>
  const subject = deusExMachina?.subject as Subject

  useEffect(() => {
    const fetchStoreConfig = async () => {
      if (!entity?.document) return

      if (subject?.id) {
        const experimentData = await getAllExperiment(subject.id, true, true, {
          swh_account_document: entity?.document
        })
        setExperiment(experimentData)
      }

      const store = await Stores.getByDocument(entity.document)
      if (store) {
        setConfig({ store, document: entity.document, affiliationKey: entity?.stoneCodes?.[0]?.affiliationKey ?? null })
      }
    }

    fetchStoreConfig()
  }, [entity, setConfig, subject?.id])

  if (entity === undefined || config?.store?.id === undefined || experiment === undefined) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <Spinner color="brand" size="xlarge" />
      </div>
    )
  }

  if ((entity?.document && isValidCpf(entity.document)) || (experiment && isSwhExperimentOff(experiment))) {
    BlackBird.travelTo('/404')
    return null
  }

  return <Page {...props} />
}

export function withSwhStoreConfig<P extends NextPage<any, any>>(Page: P) {
  return (props: any) => <WithSwhConfig {...props} Page={Page} />
}
