import { type ExperimentValues } from '@stone-payments/experiment/src/lib/types'

// TODO: [Web Platform Refactor] Fix this circular dependencie
// eslint-disable-next-line @nx/enforce-module-boundaries
import { experimentInstance } from '~/domains/platform/lib/analitica/analitica.config'
// TODO: [Web Platform Refactor] Fix this circular dependencie
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useQueryRequest } from '~/ui/hooks/utils/service/use-query-request'

export async function getAllExperiment(
  subjectId: string,
  reset = false,
  ignorePersistence = false,
  userProperties = {}
) {
  try {
    return await experimentInstance.fetch(
      //@ts-ignore CREDIT CHANGE TO RUN CHECK-TYPES SUCCESSFULLY
      { user_id: subjectId, user_properties: userProperties },
      reset,
      ignorePersistence
    )
  } catch (error) {
    console.error(error)
  }
}

export const useAllExperiment = (
  subjectId: string,
  reset?: boolean,
  ignorePersistence?: boolean,
  userProperties?: { [key: string]: string }
) => {
  const {
    data: response,
    isLoading,
    ...restQuery
  } = useQueryRequest<ExperimentValues, unknown, true>(['useAllExperiment'], () =>
    getAllExperiment(subjectId, reset, ignorePersistence, userProperties)
  )

  return { data: response, isLoading, ...restQuery }
}
