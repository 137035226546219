export const Endpoints = {
  invoices: {
    getAll: (id: string) => `/v1/stores/${id}/invoices`
  },
  stores: {
    getByDocument: (document: string) => `/v1/stores/by/document/${document}`,
    getById: (id: string) => `/v1/stores/${id}`,
    update: (id: string) => `/v1/stores/${id}`
  },
  invoiceConfiguration: {
    getByStoreId: (id: string) => `/v1/stores/${id}/invoice-config`,
    update: (id: string) => `/v1/stores/${id}/invoice-config`
  },
  products: {
    all: (storeId: string) => `/v1/stores/${storeId}/products`,
    id: (storeId: string, productId: string) => `/v1/stores/${storeId}/products/${productId}`,
    status: (storeId: string, productId: string) => `/v1/stores/${storeId}/products/${productId}/status`
  },
  variations: (id: string) => `/v1/stores/${id}/variation-types`,
  variationOptions: (storeId: string, id: string) => `/v1/stores/${storeId}/variation-types/${id}/options`,
  categories: (id: string) => `/v1/stores/${id}/categories`,
  subCategories: (id: string, categoryId: string) => `/v1/stores/${id}/categories/${categoryId}/subcategories`,
  getByStoreId: (id: string) => `/stores/${id}/invoice-config`,
  update: (id: string) => `/stores/${id}/invoice-config`,
  sales: {
    getAll: (id: string) => `/v1/stores/${id}/sales`,
    getSwhSaleById: (saleId: string, storeId: string) => `/v1/stores/${storeId}/sales/${saleId}`,
    getAllPaymentLayerSales: (cnpj: string) => `/v1/organization/${cnpj}/sales`,
    getPaymentLayerSaleById: (cnpj: string, saleId: string) => `/v1/organization/${cnpj}/sales/${saleId}`
  }
} as const
