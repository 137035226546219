import { type ExperimentValues } from '@stone-payments/experiment/src/lib/types'
import { isSwhExperimentOn } from 'swh/shared/utils'

import { Heimdall } from '~/domains/platform/core/heimdall'
import { type Entity, type Subject } from '~/domains/platform/infra/deus-ex-machina/types'
import { appEnv } from '~/lib/helpers'

import analitica from './analitica'

export function miscellaneousProfileMenu(subject: Subject, entity: Entity, expData: ExperimentValues | undefined) {
  const hasSwh = isSwhExperimentOn(expData, entity?.document)
  const hasNewReportsPageAccess = Heimdall.pass(['feature_reports_web'])

  return [
    {
      order: 10,
      label: 'Relatórios',
      icon: 'chart-pie-outline',
      show: subject.unsafe.stoneCodes.length > 0 && !hasNewReportsPageAccess,
      path: '/relatorios',
      onClick: () => {
        analitica.events.profileMenu.click('relatorios')
      },
      active: (route: string) => ['/miscellaneous/reports', '/relatorios'].includes(route)
    },
    {
      order: 2,
      label: 'Informe de rendimentos',
      icon: 'file-folded-outline',
      show: true,
      onClick: () => {
        analitica.events.profileMenu.click('informe de rendimentos')
      },
      path: '/informe-de-rendimentos',
      active: (route: string) => ['/settings/report-income', '/informe-de-rendimentos'].includes(route)
    },
    {
      order: 3,
      label: 'Contratos',
      icon: 'percent-outline',
      show: true,
      onClick: () => {
        analitica.events.profileMenu.click('contratos')
      },
      path: '/contratos',
      active: (route: string) => ['/settings/contracts', '/contratos'].includes(route)
    },
    {
      order: 6,
      label: 'Taxas e tarifas',
      icon: 'money-coin-outline',
      show: true,
      onClick: () => {
        analitica.events.profileMenu.click('taxas e tarifas')
      },
      path: '/tarifas',
      active: (route: string) => ['/settings/fees', '/tarifas'].includes(route)
    },
    {
      order: 9,
      label: 'Central de ajuda',
      icon: 'round-help-outline',
      show: Boolean(appEnv.isProduction),
      onClick: () => {
        analitica.events.profileMenu.click('central de ajuda')

        window.open('https://ajuda.stone.com.br', '_blank')
      }
    },
    {
      order: 25,
      label: 'Nota fiscal',
      icon: 'file-unfolded-outline',
      show: hasSwh,
      onClick: () => {},
      path: '/swh/configuracoes/nota-fiscal',
      active: (route: string) => ['/swh/settings/invoice', '/swh/configuracoes/nota-fiscal'].includes(route)
    }
  ]
}
