// @ts-nocheck
import { parse, stringify } from 'qs'
import { type z, ZodArray, ZodDefault } from 'zod'

const sort = (a: string, b: string) => a.localeCompare(b)

type NullToUndefined<T> = T extends null ? undefined : T

type RecNTU<T> = {
  [K in keyof T]: T[K] extends object ? RecNTU<T[K]> : T[K] extends any[] ? RecNTU<T[K]> : NullToUndefined<T[K]>
}

const options = {
  sort,
  allowDots: true,
  charset: 'utf-8',
  parseArrays: true,
  plainObjects: true,
  charsetSentinel: true,
  allowPrototypes: false,
  depth: Number.MAX_SAFE_INTEGER,
  arrayLimit: Number.MAX_SAFE_INTEGER,
  parameterLimit: Number.MAX_SAFE_INTEGER
} as const

export const formToJson = <T>(form: HTMLFormElement): RecNTU<T> => {
  const formData = new FormData(form)
  const urlSearchParams = new URLSearchParams(formData as any)
  return parse(urlSearchParams.toString(), options) as never
}

export const permutation = <T extends any[]>(...args: T): T => {
  if (args.length === 0) {
    return [] as unknown as T
  }
  const r = [] as any as T
  const max = args.length - 1
  function permute(arr: any[], i: number) {
    for (let j = 0, l = args[i].length; j < l; j++) {
      const a = arr.slice(0)
      a.push(args[i][j])
      if (i == max) r.push(a as any)
      else permute(a, i + 1)
    }
  }
  permute([], 0)
  return r
}

export const plurals = (count: number, map: Record<number, string> & { many: (c: number) => string }) =>
  count in map ? map[count] : map.many(count)

export class Dict<K, V> extends Map<K, V> {
  private items: V[] = []

  public override set(k: K, v: V) {
    super.set(k, v)
    this.update()
    return this
  }

  public override delete(k: K) {
    const r = super.delete(k)
    this.update()
    return r
  }

  public map<C extends (item: V, index: number, array: V[]) => any>(callback: C): ReturnType<C>[] {
    if (this.items.length === 0) {
      this.items = Array.from(this.values())
    }
    return this.items.map(callback)
  }

  public clone() {
    return new Dict(this)
  }

  private update() {
    this.items = Array.from(this.values())
  }
}

const setHelper = (obj: any, path: string[], value: any) => {
  const [current, ...rest] = path
  if (rest.length > 0) {
    if (!obj[current]) {
      const isNumber = `${+rest[0]}` === rest[0]
      obj[current] = isNumber ? [] : {}
    }
    if (typeof obj[current] !== 'object') {
      const isNumber = `${+rest[0]}` === rest[0]
      obj[current] = setHelper(isNumber ? [] : {}, rest, value)
    } else obj[current] = setHelper(obj[current], rest, value)
  } else obj[current] = value
  return obj
}

export const convertPath = (path: string) => (path as string).replace('[', '.').replace(']', '').split('.')

export const setPath = <O extends object>(o: O, path: Array<string | number> | string, value: any) => {
  const pathArr = Array.isArray(path) ? path : convertPath(path)
  const obj = structuredClone(o)
  setHelper(obj, pathArr as string[], value)
  return obj
}

export const getPath = <T>(obj: T, path: string | string[], defValue?: any) => {
  if (!path) return undefined
  const pathArray: any = Array.isArray(path) ? path : path.match(/([^[.\]])+/g)
  const result = pathArray.reduce((prevObj: any, key: any) => prevObj && prevObj[key], obj)
  return result === undefined ? defValue : result
}

//@ts-ignore CREDIT CHANGE TO RUN CHECK-TYPES SUCCESSFULLY
export const getLeaf = (something: any) => {
  if (typeof something === 'string') return something
  if (!something) return null
  const leaf = Array.isArray(something) ? something[0] : Object.values(something)[0]
  if (!leaf) return undefined
  return getLeaf(leaf)
}

//@ts-ignore CREDIT CHANGE TO RUN CHECK-TYPES SUCCESSFULLY
export const debounce = <FN extends (...a: any[]) => any>(fn: FN, ms = 0) => {
  let timeoutId: NodeJS.Timeout
  const pending = []
  return (...args: Parameters<FN>) =>
    new Promise((res, rej) => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        const currentPending = [...pending]
        pending.length = 0
        Promise.resolve(fn.apply(this, args)).then(
          data => {
            currentPending.forEach(({ resolve }) => resolve(data))
          },
          error => {
            currentPending.forEach(({ reject }) => reject(error))
          }
        )
      }, ms)
      pending.push({ resolve: res, reject: rej })
    })
}

export const getSchemaShape = <T extends z.ZodObject<any>>(name: string, schema: T): z.ZodAny =>
  convertPath(name).reduce((acc, el) => {
    if (el === '') return acc
    const shape = acc.shape?.[el] || acc
    if (shape instanceof ZodArray) {
      return shape.element
    }
    if (shape instanceof ZodDefault) {
      return shape._def.innerType
    }
    return shape
  }, schema)

type IssuePath = string | number

export const parseIssues = (list: Array<{ message: string; path: IssuePath[] }>) => {
  const result = list.map(x => `${x.path.join('.')}=${x.message}`).join('&')
  return parse(result, options)
}

const queryStringOptions = {
  addQueryPrefix: true,
  allowDots: true,
  allowEmptyArrays: true,
  skipNulls: true,
  strictNullHandling: true,
  sort: (a, b) => a.localeCompare(b)
}

export const toQueryString = <O extends object>(pathname: string, o: O) => stringify(o, queryStringOptions)
