import { type QueryFunctionContext, useQuery } from '@tanstack/react-query'

import { Endpoints } from '../endpoints'
import { httpClient } from '../http-client'
import { handleErrorResponse } from '../utils/errors'

const ITEMS_PER_PAGE = 25

export namespace PaymentLayerSales {
  export type PaymentMethods = 'pix' | 'boleto' | 'card'
  export type PaymentMethodTypes = 'credit' | 'debit' | 'voucher' | 'dynamic' | 'boleto'
  export type Origin = 'pos' | 'e-commerce' | 'tef'
  export type CardBrand =
    | 'Visa'
    | 'MasterCard'
    | 'AmericanExpress'
    | 'Cabal'
    | 'Ticket'
    | 'Sodexo'
    | 'VR'
    | 'Alelo'
    | 'Hipercard'
    | 'Senff'
    | 'VerdeCard'
    | 'CooperCard'
    | 'GreenCard'
    | 'UpBrasil'
    | 'VeroCard'
    | 'ValeCard'
    | 'SoroCred'
    | 'BanesCard'
    | 'BenVisaVale'
    | 'Elo'
    | 'UnionPay'
    | 'RedeCompras'

  export enum Status {
    'Authorized' = 'Authorized',
    'Approved' = 'Approved',
    'Cancelled' = 'Cancelled',
    'TransactionFail' = 'TransactionFail',
    'Dispute' = 'Dispute'
    // "Captured" = "Captured",
    // "PreAuthorization" = "PreAuthorization"
  }

  export type Item = {
    id: string
    createdAt: string
    paymentMethod: PaymentMethods
    paymentMethodType: PaymentMethodTypes
    installments: number | null
    cardBrand: CardBrand | null
    status: Status
    origin: Origin
    serialNumber: string | null
    amount: 100000
  }

  export type Response = {
    items: Item[]
    size?: 1
    current?: 1
    next?: number
    total?: 3
  }

  type FilterContent = {
    value: string | number
  }

  type Filter = {
    selectedPeriodOption: { initialDate: Date; endDate: Date }
    selectedCaptureMethodsOptions: FilterContent[]
    selectedPaymentsOptions: FilterContent[]
  }
  export const getAll = async (
    cnpj: string | undefined,
    next: number,
    filter: Filter
  ): Promise<Response | undefined> => {
    if (cnpj === undefined) return undefined
    const endpoint = Endpoints.sales.getAllPaymentLayerSales(cnpj)
    try {
      const response = await httpClient.get<Response>(endpoint, {
        params: {
          pageSize: ITEMS_PER_PAGE,
          next: next,
          mockMode: 'paymentLayer',
          captureMethods: filter.selectedCaptureMethodsOptions.map(el => el.value).join(','),
          paymentMethods: filter.selectedPaymentsOptions.map(el => el.value).join(','),
          start: filter.selectedPeriodOption.initialDate.toISOString(),
          end: filter.selectedPeriodOption.endDate.toISOString()
        }
      })
      return response.data
    } catch (error: unknown) {
      handleErrorResponse(error)
    }
  }
}

export namespace PaymentLayerSaleDetail {
  export type SalesProductsItem = {
    id: string
    name: string
    image: string
    price: number
    quantity: string
  }

  export type Response = {
    amount: number
    updatedAmount: number
    status: string
    paymentMethod: string
    createdAt: string
    merchant: Merchant
    boletoTransaction: BoletoTransaction | null
    cardTransaction: CardTransaction | null
    pixTransaction: PixTransaction | null
    netAmount: number
    items: SalesProductsItem[]
  }

  export type Merchant = {
    organization: string
    document: string
    userId: string
    affiliationKey: string
  }

  export type BoletoTransaction = {
    type: 'bill_of_exchange' | 'proposal'
    transactionId: string
    ourNumber: string
    fineAmount: number
    interestAmount: number
    feeAmount: number
    expireAt: string
    writableLine: string
    bank: Bank
    fine: Fine
  }

  export type Bank = {
    name: string
  }

  export type Fine = {
    value: string
  }

  export type CardTransaction = {
    type: string
    transactionId: string
    capturedAmount: number
    canceledAmount: number
    chargebackAmount: number
    chargebackRefundAmount: number
    authorizationCode: string
    installments: number
    card: Card
    details: Detail[]
    feeAmount: number
    mdrAmount: number
    mdrRefundAmount: number
    ravAmount: number
    ravRefundAmount: number
    terminal: Terminal
  }

  export type Card = {
    brand: string
    firstSixDigits: string
    lastFourDigits: string
  }

  export type Detail = {
    operation: string
    providerDatetime: string
    operationReason: string
  }

  export type Terminal = {
    type: string
    serialNumber: string
  }

  export type PixTransaction = {
    transactionId: string
    e2eId: string
    feeAmount: number
    payer: Payer
    terminal: Terminal
  }

  export type Payer = {
    name: string
    document: string
    institutionName: string
  }

  const getSale = async (ctx: QueryFunctionContext): Promise<Response> => {
    const [, cnpj, saleId] = ctx.queryKey
    try {
      const response = await httpClient.get<Response>(
        Endpoints.sales.getPaymentLayerSaleById(cnpj as string, saleId as string)
      )
      return response.data
    } catch (error: unknown) {
      return handleErrorResponse(error)
    }
  }
  export function useFetchSalesById(cnpj: string | null | undefined, saleId: string | undefined) {
    return useQuery<Response, unknown>({
      queryKey: ['sales-by-id', cnpj, saleId],
      queryFn: getSale,
      refetchOnWindowFocus: false,
      enabled: !!cnpj && !!saleId
    })
  }
}
