import { type ChangeEvent, type FormEvent, useEffect, useState } from 'react'
import {
  type Option,
  Button,
  CardContainer,
  Icon,
  InputField,
  PageTitle,
  Select,
  Switch,
  Text
} from '@stone-payments/jade'
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  type CustomVariationOption,
  type ErrorProperties,
  type VariationType,
  ErrorsProvider,
  PageSwhContainer,
  SwhProductForm,
  UpsertMode,
  useErrors,
  useSwhConfig,
  VariantsForm
} from 'swh/shared/components'
import { Categories, ProductCatalog } from 'swh/shared/http'
import { formToJson, getSchemaShape, parseIssues, setPath } from 'swh/shared/utils'

import BlackBird from '~/domains/platform/lib/blackbird'

import { Actions } from './actions'

export type UpsertProductState = {
  name: string
  description: string
  categoryId: string
  subCategoryId: string
  sku: boolean
  gtin: boolean
  hasVariants: boolean
  variants: Actions.Variant[]
}

export type UpsertProductProps = {
  apiErrors: ErrorProperties
  categories: Option[]
  errors: ErrorProperties
  loading: boolean
  mode: UpsertMode
  product: ProductCatalog.GetProductById | null
  state: UpsertProductState
  subCategories: Option[]
  onSubmit: (e: FormEvent<HTMLFormElement>, data: Actions.PostProduct) => Promise<void> | void
}

const BasicData = (props: {
  subCategories?: Option[]
  categories: Option[]
  state: UpsertProductState
  storeId?: string
}) => {
  const [errors] = useErrors()
  const [category, setCategory] = useState(props.state.categoryId)
  const [subCategories, setSubCategories] = useState<Option[] | null>(props.subCategories ?? null)
  const [subCategory, setSubCategory] = useState('')

  useEffect(() => {
    if (Array.isArray(props.subCategories)) setSubCategories(props.subCategories)
  }, [props.subCategories])

  const onChangeCategory = async (e: ChangeEvent<HTMLSelectElement>) => {
    const storeId = props.storeId
    if (!storeId) return
    const categoryId = e.target.value
    setCategory(categoryId)
    try {
      const json = await Categories.subCategories(storeId, categoryId)
      setSubCategories(json.map(x => ({ value: x.id, label: x.name })))
    } catch (error) {
      setSubCategories([])
    }
  }

  return (
    <CardContainer safeArea>
      <Text variant="heading-xsmall" className="!mb-4">
        Dados básicos
      </Text>
      <div className="flex flex-col gap-4">
        <p className="text-jade-75">Preencha os campos com informações gerais do produto que está cadastrando</p>
        <InputField
          label="Nome"
          name="name"
          error={!!errors?.name}
          placeholder="Nome do produto"
          defaultValue={props.state.name}
          supportText={errors?.name as string}
        />
        <InputField
          optional
          required={false}
          label="Descrição"
          name="description"
          placeholder="Descrição"
          defaultValue={props.state.description}
          maxLength={255}
          hideCount
        />
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <Select
            value={category}
            label="Categoria"
            name="categoryId"
            options={props.categories}
            onChange={onChangeCategory}
            error={!!errors?.categoryId}
            placeholder="Nome da categoria"
            supportText={errors?.categoryId as string}
          />
          <Select
            disabled={subCategories === null || (Array.isArray(subCategories) && subCategories.length === 0)}
            label="Sub-categoria"
            name="subCategoryId"
            onChange={e => setSubCategory(e.target.value)}
            optional
            options={subCategories ?? []}
            placeholder="Escolha uma opção"
            value={props.state.subCategoryId || subCategory}
          />
        </div>
      </div>
    </CardContainer>
  )
}

const AdditionalInfo = (props: Omit<UpsertProductProps, 'onSubmit'>) => {
  const [errors] = useErrors()
  const [state, setState] = useState(() => ({
    sku: props.state.sku,
    gtin: props.state.gtin,
    variants: props.state.variants,
    hasVariants: props.state.hasVariants
  }))

  const onCheck = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.name
    const checked = e.currentTarget.checked
    return setState(prev => ({ ...prev, [name]: checked }))
  }

  const onCheckVariants = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.currentTarget.checked
    return setState(prev => ({ ...prev, hasVariants: checked, variants: checked ? [] : [Actions.createVariant()] }))
  }

  const onCreateVariantOptions = (
    variants: VariationType[],
    combinations: Array<{ combinations: CustomVariationOption[]; name: string }>
  ) => {
    const newCombinations = combinations.map(
      (variant): Actions.Variant => ({
        ...Actions.createVariant(),
        name: variant.name,
        variationTypes: variants.map(x => x.id),
        variationsOptions: variant.combinations.map(x => ({ name: x.name, id: x.id, parentId: x.parentId }))
      })
    )
    setState(prev => ({ ...prev, variants: newCombinations }))
  }

  return (
    <CardContainer safeArea>
      <Text variant="heading-xsmall" className="!mb-4">
        Informações adicionais
      </Text>
      <div className="flex flex-col gap-4">
        <p className="text-jade-75">
          Preencha os campos de códigos e valores do seu produto ou crie variações com como cor e tamanho com dados
          específicos
        </p>
        <ul className="list-inside px-jade-200 space-y-jade-150 my-jade-50 text-base list-none text-jade-content-medium">
          <li>
            <span className="flex flex-row gap-4 justify-between items-center">
              <label className="cursor-pointer flex items-center gap-jade-100" htmlFor="sku">
                <Icon size="medium" use="arrow-left-right" />
                Produto tem SKU (Unidade de Manutenção de Estoque)
              </label>
              <Switch onChange={onCheck} checked={state.sku} name="sku" />
            </span>
          </li>
          <li>
            <span className="flex flex-row gap-4 justify-between items-center">
              <label className="cursor-pointer flex items-center gap-jade-100" htmlFor="gtin">
                <Icon size="medium" use="barcode" />
                Produto tem código de barras (GTIN)
              </label>
              <Switch onChange={onCheck} checked={state.gtin} name="gtin" />
            </span>
          </li>
          <li>
            <span className="flex flex-row gap-4 justify-between items-center">
              <label className="cursor-pointer flex items-center gap-jade-100" htmlFor="hasVariants">
                <Icon size="medium" use="menu-grid" />
                Produto tem grade de variantes (Ex: cor, tamanho, material...)
              </label>
              <Switch onChange={onCheckVariants} checked={state.hasVariants} name="hasVariants" />
            </span>
          </li>
        </ul>
        {state.hasVariants ? (
          <VariantsForm
            mode={props.mode}
            product={props.product!}
            variants={state.variants}
            onCreateVariants={onCreateVariantOptions}
          />
        ) : null}
        <SwhProductForm
          sku={state.sku}
          mode={props.mode}
          gtin={state.gtin}
          localErrors={errors}
          variants={state.variants}
          apiErrors={props.apiErrors}
          hasVariants={state.hasVariants}
        />
      </div>
    </CardContainer>
  )
}

const nonLoading = { draft: false, public: false }

const InnerUpsertProducts = (props: UpsertProductProps) => {
  const config = useSwhConfig()
  const [, setErrors] = useErrors()
  const editing = props.mode === UpsertMode.edit
  const [loading, setLoading] = useState(nonLoading)

  useEffect(() => {
    if (props.apiErrors) {
      setErrors(prev => ({ ...prev, ...props.apiErrors }))
    }
  }, [props.apiErrors, setErrors])

  const setErrorsFromFormEvent = (target: HTMLSelectElement | HTMLInputElement) => {
    const name = target.name
    const value = target.value
    const ignore = target.dataset.ignore || ''
    if (ignore !== '') return
    if (value !== '') {
      const schema = getSchemaShape(name, Actions.productSchema({ hasGtin: true, hasSku: true }))
      if (schema) {
        const validation = schema.safeParse(value)
        if (!validation.success) {
          return setErrors(prev => setPath(prev || {}, name, validation.error?.issues[0].message))
        }
        return setErrors(prev => (prev === null ? null : setPath(prev || {}, name, undefined)))
      }
    }
  }

  const onChangeForm = (e: FormEvent<HTMLFormElement>) => {
    const target = e.target as HTMLSelectElement | HTMLInputElement
    setErrorsFromFormEvent(target)
  }

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const form = e.currentTarget
    const json = formToJson(form) as any
    const hasSku = json.sku === 'on'
    const hasGtin = json.gtin === 'on'
    const submitter = (e.nativeEvent as SubmitEvent).submitter as HTMLInputElement | HTMLButtonElement
    const submitStatus = submitter.value
    const variants = json.variants
    if (!variants) {
      return setErrors({ creatingVariant: 'Criação de grade não foi concluída' })
    }
    const variationOptions = variants.items.map((x: string) => JSON.parse(x))
    const input = {
      ...json,
      images: [],
      sku: hasSku,
      gtin: hasGtin,
      status: submitStatus,
      variants: variationOptions,
      hasVariants: json.hasVariants === 'on'
    }
    const validation = Actions.productSchema({ hasSku, hasGtin }).safeParse(input)
    if (validation.success) {
      setLoading(prev => ({ ...prev, [submitStatus]: true }))
      await props.onSubmit(e, validation.data)
      setLoading(nonLoading)
      return
    }
    setLoading(nonLoading)
    const messages = validation.error.issues.map(x => ({ path: x.path, message: x.message }))
    const parsedErrors = parseIssues(messages)
    setErrors(parsedErrors as ErrorProperties)
  }

  const [statusEdit, setStatusEdit] = useState<string>(props.product?.status || '')
  useEffect(() => {
    if (props.product?.status) setStatusEdit(props.product.status)
  }, [props.product?.status])

  const onChangeStatus = async (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value
    setStatusEdit(value)
    const id = config?.store?.id
    const productId = props.product?.id
    if (!id || !productId) return
    try {
      await ProductCatalog.updateStatus(id, productId, value as ProductCatalog.ProductStatus)
    } catch (e) {
      console.error(e)
    }
  }

  const pageTrailingItem = editing ? (
    <Select
      label=""
      value={statusEdit}
      onChange={onChangeStatus}
      options={[
        { value: ProductCatalog.ProductStatus.Public, label: 'Público' },
        { value: ProductCatalog.ProductStatus.Archived, label: 'Arquivado' },
        { value: ProductCatalog.ProductStatus.Draft, label: 'Rascunho' }
      ]}
    />
  ) : undefined

  return (
    <PageSwhContainer
      viewMode="centered"
      trailingItem={pageTrailingItem}
      navigateType={editing ? 'close' : 'back'}
      label={editing ? props.product!.name : 'Cadastrar produto'}
      onNavigate={() => BlackBird.travelTo('/catalogo/produtos')}
    >
      {editing ? null : (
        <PageTitle
          title="Dados do produto"
          description="Preencha os campos com informações gerais do produto que está cadastrando"
          className="mb-jade-250"
        />
      )}

      <form onSubmit={onSubmit} onChange={onChangeForm} className="flex flex-col gap-jade-200 mb-jade-200">
        <BasicData
          state={props.state}
          storeId={config.store?.id}
          categories={props.categories}
          subCategories={props.subCategories}
        />
        <AdditionalInfo {...props} />
        <div className="flex flex-row justify-end gap-jade-100">
          <Button
            loading={loading.public}
            disabled={props.loading}
            name="submit"
            type="submit"
            value={ProductCatalog.ProductStatus.Public}
            variant="primary-solid"
          >
            Publicar
          </Button>
        </div>
      </form>
    </PageSwhContainer>
  )
}

export const UpsertProduct = (props: UpsertProductProps) => {
  return (
    <ErrorsProvider>
      <InnerUpsertProducts {...props} />
    </ErrorsProvider>
  )
}
