import { type QueryFunctionContext, useQuery } from '@tanstack/react-query'

import { Endpoints } from '../endpoints'
import { httpClient } from '../http-client'
import { handleErrorResponse } from '../utils'

import { type Invoices } from './invoices'

export namespace SwhSalesDetails {
  export type PaymentMethod = 'credit' | 'debit' | 'pix'
  type Payment = {
    id: string
    method: PaymentMethod
    amount: number
    metadata: {
      itk?: string
      atk?: string
      installmentCount?: number
      brand?: string
      authorizationCode?: string
      authorizationDateTime?: string
      cardHolderName?: string
      accountID?: string
      instantPaymentId?: string
      pan?: string
    }
  }

  export type Response = {
    id: string
    orderId: string
    status: 'finished' | string
    priceTotal: number
    itemsCount: number
    looseValuesCount: number
    createdAt: string
    looseValues: any[]
    invoice: {
      danfeUrl: string
      status: Invoices.Status
    }
    origin: {
      type: 'POS'
      serialNumber: '1234ABC'
    }
    items: {
      id: string
      variantId: string
      sku: string
      gtin: string
      name: string
      note: string
      quantity: number
      unitPrice: number
      totalPrice: number
    }[]
    payments: Payment[]
  }

  const getSale = async (ctx: QueryFunctionContext): Promise<Response> => {
    const [, saleId, storeId] = ctx.queryKey
    try {
      const response = await httpClient.get<Response>(
        Endpoints.sales.getSwhSaleById(saleId as string, storeId as string)
      )
      return response.data
    } catch (error: unknown) {
      return handleErrorResponse(error)
    }
  }
  export function useFetchSalesById(saleId: string, storeId: string | undefined) {
    return useQuery<Response, unknown>({
      queryKey: ['sales-swh-by-id', saleId, storeId],
      queryFn: getSale,
      refetchOnWindowFocus: false,
      enabled: !!saleId && !!storeId
    })
  }
}

const ITEMS_PER_PAGE = 25

export namespace SwhSales {
  export enum Status {
    Created = 'created',
    Paid = 'paid',
    Finished = 'finished',
    Canceled = 'canceled'
  }

  export type PaymentMethodTypes = 'credit' | 'debit' | 'pix'
  export type Origin = 'POS'
  export type CardBrand =
    | 'Visa'
    | 'MasterCard'
    | 'AmericanExpress'
    | 'Cabal'
    | 'Ticket'
    | 'Sodexo'
    | 'VR'
    | 'Alelo'
    | 'Hipercard'
    | 'Senff'
    | 'VerdeCard'
    | 'CooperCard'
    | 'GreenCard'
    | 'UpBrasil'
    | 'VeroCard'
    | 'ValeCard'
    | 'SoroCred'
    | 'BanesCard'
    | 'BenVisaVale'
    | 'Elo'
    | 'UnionPay'
    | 'RedeCompras'

  export type Item = {
    id: string
    status: SwhSales.Status
    totalPrice: 6800
    itemsCount: string
    looseValuesCount: string
    createdAt: string
    invoice: {
      danfeUrl: string
    }
    origin?: {
      type: Origin
      serialNumber: string
    }
    payments?: {
      method: PaymentMethodTypes
      amount: 1000
      metadata: {
        itk: string
        atk: string
        InstallmentCount: string
        brand: CardBrand | null
        AuthorizationCode: string
        AuthorizationDateTime: string
        CardHolderName: string
        AccountID: string
        InstantPaymentID: string
      }
    }[]
  }

  export type Response = {
    items: Item[]
    nextPageCursor?: string
  }

  type FilterContent = {
    value: string | number
  }

  type Filter = {
    selectedPeriodOption: { initialDate: Date; endDate: Date }
    selectedCaptureMethodsOptions: FilterContent[]
    selectedPaymentsOptions: FilterContent[]
  }

  export const getAll = async (id: string, cursor: string | null = null, filter: Filter): Promise<Response | null> => {
    const endpoint = Endpoints.sales.getAll(id)
    try {
      const response = await httpClient.get<Response>(endpoint, {
        params: {
          pageSize: ITEMS_PER_PAGE,
          cursor: cursor,
          captureMethods: filter.selectedCaptureMethodsOptions.map(el => el.value).join(','),
          paymentMethods: filter.selectedPaymentsOptions.map(el => el.value).join(','),
          start: filter.selectedPeriodOption.initialDate.toISOString(),
          end: filter.selectedPeriodOption.endDate.toISOString()
        }
      })
      return response.data
    } catch (error: unknown) {
      handleErrorResponse(error)
      return null
    }
  }
}
