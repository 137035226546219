import { useEffect, useState } from 'react'
import { type DropdownSelectionOption, type Option } from '@stone-payments/jade'
import { Categories } from 'swh/shared/http'

export const useCategoriesOptions = (storeId: string | undefined, selectedCategory: string | undefined) => {
  const [categoriesOptions, setCategoriesOptions] = useState<DropdownSelectionOption[]>([])

  const fetchCategories = async (id: string) => {
    const categories = await Categories.get(id)
    const categoriesAsOptions = categories.map(category => ({
      label: category.name,
      value: category.id.toString(),
      selected: category.id.toString() === selectedCategory
    }))
    setCategoriesOptions(categoriesAsOptions)
  }

  useEffect(() => {
    if (categoriesOptions.length === 0 || selectedCategory === undefined) return
    setCategoriesOptions(prev => prev.map(x => ({ ...x, selected: x.value === selectedCategory })))
  }, [selectedCategory])

  useEffect(() => {
    if (storeId) fetchCategories(storeId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeId])

  return categoriesOptions
}

export const useSubCategoryOptions = (storeId: string | undefined, categoryId: string | undefined) => {
  const [subCategories, setSubCategories] = useState<Option[]>([])

  useEffect(() => {
    if (storeId === undefined || categoryId === undefined) return
    const request = async () => {
      try {
        const json = await Categories.subCategories(storeId, categoryId)
        setSubCategories(json.map(x => ({ value: x.id, label: x.name })))
      } catch (error) {
        setSubCategories([])
      }
    }
    request()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeId, categoryId])

  return subCategories
}
