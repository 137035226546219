import { type DeepLinkSchema } from '~/domains/platform/infra/deep-link/entities'

export const CREDIT_ROUTES = [
  '/credito',
  '/emprestimo',
  '/emprestimo/proposta/simulacao',
  '/credito/emprestimo/proposta/simulacao',
  '/credito/emprestimo/proposta/atualizar-dados',
  '/credito/emprestimo/proposta/nacionalidade',
  '/credito/emprestimo/proposta/estado-civil',
  '/credito/emprestimo/proposta/endereco',
  '/credito/emprestimo/proposta/confirmar-dados',
  '/credito/emprestimo/proposta/revisao',
  '/credito/emprestimo/proposta/sucesso',
  '/credito/emprestimo/proposta/pin',
  '/credito/emprestimo/proposta/confirmar-pedido',
  '/credito/emprestimo/${loan}',
  '/credito/emprestimo/${loan}',
  '/credito/emprestimo/${loan}/antecipar-pagamento/${step}',
  '/credito/emprestimo/${loan}/antecipar-pagamento/inicio',
  '/credito/emprestimo/${loan}/parcela/${installment}',
  '/credito/emprestimo/${loan}/renegociacao',
  '/credito/emprestimo/${loan}/renegociacao/${step}',
  '/credito/emprestimo/${loan}/renegociacao/simulacao',
  '/credito/emprestimo/${loan}/renegociacao-condicoes',
  '/credito/emprestimo/${loan}/renegociacao-parcelas',
  '/credito/emprestimo/${loan}/credito/emprestimo-original/parcela/${installment}',
  '/credito/giro-facil/${product}',
  '/credito/giro-facil/${product}/antecipar-pagamento/${step}',
  '/credito/giro-facil/${product}/saque/valor',
  '/credito/giro-facil/${product}/saque/sucesso',
  '/credito/giro-facil/${product}/contratacao/inicio',
  '/credito/giro-facil/${product}/contratacao/${step}',
  '/credito/giro-facil/${product}/demonstrativo',
  '/credito/giro-facil/${product}/antecipar-pagamento/inicio',
  '/roleta',
  '/credito/giro-facil/contratacao'
] as const

export const renegotiationBasePath = '/credito/emprestimo/${loan}/renegociacao'
export const loanConcessionBasePath = '/credito/emprestimo/proposta/simulacao'
export const revolvingCreditConcessionBasePath = '/credito/giro-facil/contratacao'
export const creditHomeBasePath = '/credito'

export const creditDeepLinks: { [key: string]: DeepLinkSchema } = {
  '/deeplink/credito/girofacil/oferta': {
    url: '/credito/giro-facil/contratacao',
    dependency: 'id',
    type: 'offers',
    product: 'revolving_credit',
    requiresLogin: true
  },
  '/deeplink/credito/girofacil/detalhe': {
    url: '/credito/giro-facil/${product}',
    dependency: 'id',
    type: 'products',
    product: 'revolving_credit',
    requiresLogin: true
  },
  '/deeplink/credito/girofacil/saque': {
    url: '/credito/giro-facil/${product}?isWithdrawalDrawerOpen=true',
    dependency: 'id',
    type: 'products',
    product: 'revolving_credit',
    requiresLogin: true
  },
  '/deeplink/credito/capitaldegiro/oferta': {
    url: '/emprestimo/proposta/simulacao',
    product: 'loans',
    dependency: 'id',
    type: 'offers',
    requiresLogin: true
  },
  '/deeplink/emprestimo': {
    url: '/emprestimo',
    product: 'loans',
    type: 'products',
    requiresLogin: true
  }
}
