// @ts-nocheck
import { type ChangeEvent, type MouseEvent, useEffect, useMemo, useState } from 'react'
import { type Option, Alert, Button, Select, Text } from '@stone-payments/jade'
// eslint-disable-next-line @nx/enforce-module-boundaries
import { type Actions } from 'swh/catalog/products-upsert'
import { PageCard, useErrors, useSwhConfig, useVariants } from 'swh/shared/components'
import { type ProductCatalog, Variants } from 'swh/shared/http'
import { Dict, permutation, plurals } from 'swh/shared/utils'

import { uuid } from '~/domains/platform/lib/crypto'

const DEFAULT_VARIANT_OPTIONS_SELECTED = 0

const MAX_SIZE_VARIANT_OPTION = 15

export type CreateVariationOption = {
  id: string
  KEY: string
  name: string
  variantTypeId: string
}

export type VariationType = { id: string; name: string }

type CreateVariantType = VariationType & {
  variationOptions: Dict<string, CreateVariationOption>
}

export enum UpsertMode {
  new = 'new',
  edit = 'edit'
}

export type CustomVariationOption = Variants.VariationOption & {
  parentId: string
}

type VariantsFormProps = {
  mode: UpsertMode
  product: ProductCatalog.GetProductById
  variants: Actions.Variant[]
  onCreateVariants: (
    variants: VariationType[],
    combinations: Array<{ combinations: CustomVariationOption[]; name: string }>
  ) => void
}

type State = { items: Dict<string, CreateVariantType> }

const combineVariations = (items: CreateVariantType[]) => {
  if (items.length === 0) {
    return []
  }
  //@ts-ignore CREDIT CHANGE TO RUN CHECK-TYPES SUCCESSFULLY
  const flat = items.reduce((flattenList, x) => {
    if (x.name === '') return flattenList
    const items = Array.from(x.variationOptions.values())
    return [
      ...flattenList,
      items.reduce<CreateVariationOption[]>((acc, el) => (el.name === '' ? acc : [...acc, el]), [])
    ]
  }, [])
  return permutation(...flat)
}

const toVariantCombination = (id: string, x: Variants.VariationOption, KEY?: string): CreateVariationOption => ({
  KEY: KEY ?? x.id,
  id: x.id,
  name: x.name,
  variantTypeId: id
})

const getFirstVariantWithOptions = (variants: Variants.VariationType[]) =>
  variants.find(x => x.variationOptions.length > 0)

const fetchCreateModeVariants = (variants: Variants.VariationType[]): State => {
  const items = new Dict<string, CreateVariantType>()
  if (variants.length === 0) return { items }
  const first = getFirstVariantWithOptions(variants)
  if (first) {
    const opts: CreateVariationOption[] = [
      ...first.variationOptions
        .slice(0, DEFAULT_VARIANT_OPTIONS_SELECTED)
        .map(opt => toVariantCombination(first.id, opt, uuid())),
      { id: '', KEY: uuid(), variantTypeId: first.id, name: '' }
    ]
    const variantType: CreateVariantType = {
      id: first.id,
      name: first.name,
      variationOptions: new Dict<string, CreateVariationOption>(opts.map(x => [x.KEY, x]))
    }
    items.set(first.id, variantType)
  }
  return { items }
}

const fetchEditModeVariants = (product: VariantsFormProps['product']): State => {
  const items = new Dict<string, CreateVariantType>()
  if (!product) return { items }
  product.variants.forEach(variant => {
    if (!Array.isArray(variant.chosenVariationOptions)) return
    variant.chosenVariationOptions.forEach(choose => {
      const x = items.get(choose.id)
      if (x) {
        const clone = x.variationOptions.clone()
        clone.set(choose.option.id, {
          KEY: uuid(),
          variantTypeId: x.id,
          id: choose.option.id,
          name: choose.option.name
        })
        return items.set(choose.id, { ...x, variationOptions: clone })
      }
      items.set(choose.id, {
        id: choose.id,
        name: choose.name,
        variationOptions: new Dict<string, CreateVariationOption>([
          [choose.option.id, { KEY: uuid(), variantTypeId: choose.id, id: choose.option.id, name: choose.option.name }]
        ])
      })
    })
  })
  items.forEach(x => {
    const id = uuid()
    x.variationOptions.set(id, { KEY: id, variantTypeId: x.id, id: '', name: '' })
  })
  return { items }
}

export const VariantsForm = (props: VariantsFormProps) => {
  const swhConfig = useSwhConfig()
  const variants = useVariants(swhConfig.store?.id)
  const isInsertMode = props.mode === UpsertMode.new
  const [state, setState] = useState<State>(() =>
    isInsertMode ? fetchCreateModeVariants([]) : fetchEditModeVariants(props.product)
  )
  const items = Array.from(state.items.values())
  const combinations = combineVariations(items)
  const [errors] = useErrors()
  const creatingVariantError = errors?.creatingVariant

  useEffect(() => {
    if (variants.length > 0) {
      setState(isInsertMode ? fetchCreateModeVariants(variants) : fetchEditModeVariants(props.product))
    }
  }, [props.product, variants])

  const builtinVariants = useMemo(
    () => variants.reduce<Record<string, Variants.VariationType>>((acc, el) => ({ ...acc, [el.id]: el }), {}),
    [variants]
  )

  const variantOptions: Option[] = variants.reduce(
    (acc, x) =>
      x.variationOptions.length === 0 ? acc : [...acc, { value: x.id, label: x.name, hidden: state.items.has(x.id) }],
    []
  )

  const onChangeVariantType = (e: ChangeEvent<HTMLSelectElement>) => {
    const id = e.target.value
    const toRemove = e.target.dataset.id!
    const selected = builtinVariants[id]
    setState(prev => {
      const clone = prev.items.clone()
      clone.delete(toRemove)
      const options = new Dict<string, CreateVariationOption>()
      const variation = builtinVariants[id]
      variation.variationOptions.slice(0, DEFAULT_VARIANT_OPTIONS_SELECTED).forEach(opt => {
        const KEY = uuid()
        options.set(KEY, { id: opt.id, name: opt.name, variantTypeId: opt.id, KEY: KEY })
      })
      const optionRef = uuid()
      options.set(optionRef, { id: '', name: '', variantTypeId: '', KEY: optionRef })
      return { ...prev, items: clone.set(id, { id, variationOptions: options, name: selected.name }) }
    })
  }

  const onDeleteVariant = (e: MouseEvent<HTMLButtonElement>) => {
    const id = e.currentTarget.dataset.id!
    const parentId = e.currentTarget.dataset.parent!
    setState(prev => {
      const clone = prev.items.clone()
      const parent = clone.get(parentId)!
      const variations = parent.variationOptions.clone()
      variations.delete(id)
      clone.set(parentId, { ...parent, variationOptions: variations })
      return { ...prev, items: clone }
    })
  }

  const addVariationType = () =>
    setState((prev): State => {
      const clone = prev.items.clone()
      const variantId = uuid()
      const variationOptionKey = uuid()
      clone.set(variantId, {
        id: variantId,
        name: '',
        variationOptions: new Dict([
          [variationOptionKey, { id: '', name: '', variantTypeId: variantId, KEY: variationOptionKey }]
        ])
      })
      return { items: clone }
    })

  const createVariants = async () => {
    const storeId = swhConfig?.store?.id
    if (!storeId) return
    if (state.items.size === 0) return
    const items = Array.from(state.items.values())
    const requests = items
      .filter(x => Array.from(x.variationOptions.values()).filter(x => x.id !== ''))
      .map(x => ({ ...x, variationOptions: Array.from(x.variationOptions.values()) }))
    const result: Array<Variants.VariationType | null> = await Promise.all(
      requests.map(async item => {
        try {
          const response = await Variants.post(
            storeId,
            {
              id: item.id,
              name: item.name,
              variationOptions: item.variationOptions.map(x => ({ id: x.id, name: x.name }))
            },
            builtinVariants[item.id].variationOptions
          )
          return response
        } catch (e) {
          return null
        }
      })
    )
    const hasError = result.some(x => x === null)
    if (hasError) {
      return console.warn(result)
    }
    // eslint-disable-next-line unused-imports/no-unused-vars
    const variationTypes = state.items.map(({ variationOptions: _, ...item }): VariationType => item)
    const combinations = permutation(
      ...result.map(x =>
        x.variationOptions.map(
          (v): CustomVariationOption => ({
            ...v,
            parentId: x.id
          })
        )
      )
    )
    const combine = combinations.map(x => ({
      combinations: x,
      name: x.map(l => l.name).join('/')
    }))
    props.onCreateVariants(variationTypes, combine)
  }

  return (
    <PageCard>
      <Text variant="overline" className="uppercase text-jade-50 font-semibold">
        Variantes do produto
      </Text>
      <p>Escolha e adicione até 3 tipos de variantes ao seu produto</p>
      {creatingVariantError ? (
        <Alert
          showIcon
          variant={'negative' as any}
          icon={'circle-error' as any}
          description={creatingVariantError}
          title="Não foi possível publicar seu produto"
        />
      ) : null}
      {state.items.map((variationType, variationTypeIndex) => (
        <div
          key={variationType.id}
          className="flex flex-row flex-nowrap gap-4 w-full border-0 pb-jade-200 border-b border-solid border-jade-border-low"
        >
          <Select
            className="min-w-52"
            data-id={variationType.id}
            label="Tipo da variante"
            name={`items[${variationTypeIndex}].value`}
            onChange={onChangeVariantType}
            options={variantOptions}
            value={variationType.id}
          />
          <ul className="w-full gap-jade-200 flex flex-col">
            {variationType.variationOptions.map((variationOption, variationOptionIndex) => {
              const isFirst = variationOptionIndex === 0
              const current = builtinVariants[variationType.id]
              const options = current?.variationOptions ?? []

              const dropdownOptions = options.reduce<Option[]>(
                (acc, x) => {
                  const variantOptions = state.items.get(variationType.id)!
                  const values = Array.from(variantOptions.variationOptions.values())
                  const hasOption = values.some(s => s.id === x.id)
                  return [...acc, { value: x.id, label: x.name, hidden: hasOption }]
                },
                [{ value: '', label: 'Adicionar outra opção', hidden: true }]
              )

              const onOptionChange = (event: { value: string; label: string }) => {
                setState(prev => {
                  const stateClone = prev.items.clone()
                  const itemClone = stateClone.get(variationType.id)
                  if (!itemClone) return
                  const opts = itemClone.variationOptions.clone()
                  opts.set(variationOption.KEY, {
                    id: event.value,
                    name: event.label,
                    KEY: variationOption.KEY,
                    variantTypeId: variationOption.variantTypeId
                  })
                  const items = Array.from(opts.values())
                  const emptyItems = items.filter(x => x.id === '')
                  if (emptyItems.length === 0) {
                    if (opts.size <= MAX_SIZE_VARIANT_OPTION) {
                      const KEY = uuid()
                      opts.set(KEY, {
                        id: '',
                        KEY,
                        name: '',
                        variantTypeId: variationOption.variantTypeId
                      })
                    }
                  }
                  stateClone.set(variationType.id, { ...itemClone, variationOptions: opts })
                  return { items: stateClone }
                })
              }

              const empty = dropdownOptions.length === 0

              return (
                <li key={variationOption.KEY} className="w-full flex flex-row gap-2 items-end">
                  <Select
                    className="w-full"
                    title="Opções da variante"
                    options={dropdownOptions}
                    data-parent={variationType.id}
                    onChangeOption={onOptionChange}
                    value={variationOption.id}
                    placeholder={empty ? 'Não há opções disponíveis' : 'Adicionar outra opção'}
                    label={isFirst ? 'Opções da variante' : ''}
                    key={`dropdown-selection-${variationOption.KEY}`}
                    disabled={empty}
                  />
                  <div className={isFirst ? 'mt-1' : ''}>
                    <Button
                      icon="trash"
                      variant="neutral-ghost"
                      onClick={onDeleteVariant}
                      data-id={variationOption.KEY}
                      data-parent={variationType.id}
                      disabled={variationOption.name === ''}
                    />
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      ))}
      <div className="flex justify-end gap-jade-100">
        <Button disabled={state.items.size >= 3} size="small" onClick={addVariationType} variant="neutral-ghost">
          Adicionar outro tipo
        </Button>
        <Button size="small" onClick={createVariants} variant={creatingVariantError ? 'danger-ghost' : undefined}>
          Criar grade{' '}
          {plurals(combinations.length, {
            0: '',
            1: '(uma variante)',
            many: c => `(${c} variantes)`
          })}
        </Button>
      </div>
    </PageCard>
  )
}
